import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import LocalStorageService from "../../common/services/LocalStorageService";
import axiosClient from "../../common/api/request";

export const userLogin = createAsyncThunk(
  "auth/login",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/auth/`,
        params,
        config
      );
      // store user's token in local storage
      LocalStorageService.set("authorizationData", {
        access: data?.access,
        refresh: data?.refresh,
        is_superuser: data?.user?.is_superuser,
        group_permissions: data?.user?.group_permissions,
        groups: data?.user?.groups,
      });
      return data;
    } catch (error) {
      if (error?.response && error?.response?.data?.detail) {
        return rejectWithValue(error?.response?.data?.detail);
      } else {
        return rejectWithValue({
          SERVER_ERROR: error?.message,
        });
      }
    }
  }
);

export const signUp = createAsyncThunk(
  "sign_up",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/patient_sign_up/`,
        params,
        config
      );
      return data;
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue({
          SERVER_ERROR: error?.message,
        });
      }
    }
  }
);

export const getMe = createAsyncThunk(
  "me",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/me/`,
        { params },
        config
      );
      return data;
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue({
          SERVER_ERROR: error?.message,
        });
      }
    }
  }
);

export const patchMe = createAsyncThunk(
  "patch_me",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.patch(
        `${config.API_URL}/account/`,
        params,
        config
      );
      return data;
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue({
          SERVER_ERROR: error?.message,
        });
      }
    }
  }
);
