import React, { useRef, useEffect, useState, useCallback } from "react";
import "./Register.scss";
import { Modal, Form, Typography, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { resetAuthError } from "../../features/auth/authSlice";
import { PhoneNumberInput, Captcha } from "../../components";
import Utils from "../../utils";
import { getCaptcha } from "../../features/captcha/captchaActions";

export default function Register({ isOpened, title, onOk, onCancel, token }) {
  const [messageApi, contextHolder] = message.useMessage();
  const openMessage = useCallback(
    (type, text) => {
      messageApi.open({
        type: type,
        content: text,
      });
    },
    [messageApi]
  );
  const { loading, error, success } = useSelector((state) => state?.auth);
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [captcha, setCaptcha] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reGenerateCaptcha = useCallback(() => {
    setIsLoading(true);
    dispatch(getCaptcha())?.then((res) => {
      if (res?.type === "get_captcha/fulfilled") {
        setCaptcha(res?.payload);
      }
      setIsLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (isOpened) {
      setIsLoading(true);
      dispatch(getCaptcha())?.then((res) => {
        if (res?.type === "get_captcha/fulfilled") {
          setCaptcha(res?.payload);
        }
        setIsLoading(false);
      });
    }
  }, [dispatch, isOpened]);

  useEffect(() => {
    if (isOpened) {
      if (error && !loading) {
        for (let key in error) {
          if (key === "non_field_errors") {
            if (error[key]) {
              if (error[key][0]?.includes("captcha")) {
                formRef?.current?.setFields([
                  {
                    name: "captcha_value", // Field name you want to set the error for
                    errors: [error[key]], // Assuming your error has a 'message' field
                  },
                ]);
              }
            }
          } else {
            formRef?.current?.setFields([
              {
                name: key, // Field name you want to set the error for
                errors: [error[key]], // Assuming your error has a 'message' field
              },
            ]);
          }
        }
        if (error?.SERVER_ERROR) {
          openMessage("error", error?.SERVER_ERROR);
        }
        reGenerateCaptcha();
      }
    }
  }, [dispatch, loading, error, isOpened, reGenerateCaptcha, openMessage]);

  useEffect(() => {
    if (isOpened) {
      if (success) {
        onCancel();
        formRef.current.resetFields();
        dispatch(resetAuthError());
      }
    }
  }, [success, dispatch, isOpened, onCancel]);

  return (
    <>
      {contextHolder}
      <Modal
        className="signUp-cont"
        open={isOpened}
        title={title}
        centered={true}
        destroyOnClose={true}
        okText={"Register"}
        cancelButtonProps={{
          className: "signUp-cont__cancelBtn",
        }}
        okButtonProps={{
          size: "Larg",
          className: "signUp-cont__btn",
          htmlType: "submit",
          type: "primary",
          key: "submit",
          loading: loading,
          disabled: error ? Object.keys(error)?.length !== 0 : false,
        }}
        onCancel={() => {
          formRef.current.resetFields();
          onCancel();
          if (error) {
            dispatch(resetAuthError());
          }
        }}
        onOk={() => {
          formRef?.current?.submit();
        }}>
        <Form
          className="signUp-cont__form"
          ref={formRef}
          layout="vertical"
          onFinish={(values) => {
            delete values?.confirm;
            onOk({
              ...values,
              phone_number: values?.phone_number,
              captcha_key: captcha?.captcha_key ? captcha?.captcha_key : null,
            });
          }}
          onFinishFailed={(error) => {
            console.log("error", error);
          }}>
          <div className="signUp-cont__form__formItemWrapper">
            <Form.Item
              className="signUp-cont__form__formItemWrapper__formItem"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please enter your first name!",
                },
              ]}
              label={
                <Typography.Text className="signUp-cont__form__formItemWrapper__formItem__text">
                  First Name
                </Typography.Text>
              }
              colon={false}>
              <Input
                type="text"
                placeholder="First Name"
                size="large"
                onChange={() => {
                  if (error && error["first_name"]) {
                    dispatch(resetAuthError("first_name"));
                  }
                  if (error && error?.SERVER_ERROR) {
                    dispatch(resetAuthError("SERVER_ERROR"));
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              className="signUp-cont__form__formItemWrapper__formItem"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Please enter your last name!",
                },
              ]}
              label={
                <Typography.Text className="signUp-cont__form__formItemWrapper__formItem__text">
                  Last Name
                </Typography.Text>
              }
              colon={false}>
              <Input
                type="text"
                placeholder="Last Name"
                size="large"
                onChange={() => {
                  if (error && error["last_name"]) {
                    dispatch(resetAuthError("last_name"));
                  }
                  if (error && error?.SERVER_ERROR) {
                    dispatch(resetAuthError("SERVER_ERROR"));
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="signUp-cont__form__formItemWrapper">
            <Form.Item
              className="signUp-cont__form__formItemWrapper__formItem"
              name="phone_number"
              rules={[
                {
                  required: true,
                  message: "Please enter your phone number!",
                },
              ]}
              label={
                <Typography.Text className="signUp-cont__form__formItemWrapper__formItem__text">
                  Phone
                </Typography.Text>
              }
              colon={false}>
              <PhoneNumberInput
                size="large"
                onChange={() => {
                  if (error && error["phone_number"]) {
                    dispatch(resetAuthError("phone_number"));
                  }
                  if (error && error?.SERVER_ERROR) {
                    dispatch(resetAuthError("SERVER_ERROR"));
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              className="signUp-cont__form__formItemWrapper__formItem"
              name="email"
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  validator: Utils?.emailValidator,
                },
              ]}
              label={
                <Typography.Text className="signUp-cont__form__formItemWrapper__formItem__text">
                  Email
                </Typography.Text>
              }
              colon={false}>
              <Input
                type="text"
                placeholder="Email"
                size="large"
                onChange={() => {
                  if (error && error["email"]) {
                    dispatch(resetAuthError("email"));
                  }
                  if (error && error?.SERVER_ERROR) {
                    dispatch(resetAuthError("SERVER_ERROR"));
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="signUp-cont__form__formItemWrapper">
            <Form.Item
              className="signUp-cont__form__formItemWrapper__formItem"
              name="password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              label={
                <Typography.Text className="signUp-cont__form__formItemWrapper__formItem__text">
                  Password
                </Typography.Text>
              }
              colon={false}>
              <Input.Password
                placeholder="Password"
                size="large"
                onChange={() => {
                  if (error && error["password"]) {
                    dispatch(resetAuthError("password"));
                  }
                  if (error && error?.SERVER_ERROR) {
                    dispatch(resetAuthError("SERVER_ERROR"));
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              className="signUp-cont__form__formItemWrapper__formItem"
              name="confirm"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
              label={
                <Typography.Text className="signUp-cont__form__formItemWrapper__formItem__text">
                  Confirm Password
                </Typography.Text>
              }
              colon={false}>
              <Input.Password
                placeholder="Confirm Password"
                size="large"
                onChange={() => {
                  if (error && error["confirm"]) {
                    dispatch(resetAuthError("confirm"));
                  }
                  if (error && error?.SERVER_ERROR) {
                    dispatch(resetAuthError("SERVER_ERROR"));
                  }
                }}
              />
            </Form.Item>
          </div>
          <div
            className="signUp-cont__form__formItemWrapper"
            style={{
              marginTop: "12px",
            }}>
            <Captcha
              captcha={captcha}
              isLoading={isLoading}
              setCaptcha={setCaptcha}
              setIsLoading={setIsLoading}
            />
            <Form.Item
              className="signUp-cont__form__formItemWrapper__formItem"
              name="captcha_value"
              rules={[
                {
                  required: true,
                  message: "Please enter captcha value!",
                },
              ]}
              colon={false}>
              <Input
                placeholder="Captcha value"
                size="large"
                disabled={loading}
                onChange={() => {
                  if (error && error["captcha_value"]) {
                    dispatch(resetAuthError("captcha_value"));
                  }
                  if (error && error["non_field_errors"]) {
                    if (error["non_field_errors"][0]?.includes("captcha")) {
                      dispatch(resetAuthError("non_field_errors"));
                    }
                  }
                  if (error && error?.SERVER_ERROR) {
                    dispatch(resetAuthError("SERVER_ERROR"));
                  }
                }}
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
}
