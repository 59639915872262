import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import "./Profile.scss";
import {
  theme,
  Layout,
  Form,
  Typography,
  Input,
  DatePicker,
  Button,
  Upload,
  Progress,
  message,
  Divider,
  Spin,
  notification,
} from "antd";
import { ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { ProfileHeader } from "./components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import RolesHelper from "../../common/services/permissions/RolesHelper";
import { patchPatient } from "../../features/patient/patientActions";
import { getMe, patchMe } from "../../features/auth/authActions";
import { resetPatientError } from "../../features/patient/patientSLice";
import { resetAuthError } from "../../features/auth/authSlice";
import { PhoneNumberInput } from "../../components";
import { ConfirmModal } from "../../modals";
import axiosClient from "../../common/api/request";
import config from "../../config";
import classNames from "classnames";
import dayjs from "dayjs";
import Utils from "../../utils";

const { Content } = Layout;

export default function Profile() {
  const { useToken } = theme;
  const { token } = useToken();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = useCallback(
    (type, title, text) => {
      api[type]({
        message: title,
        description: text,
        placement: "top",
      });
    },
    [api]
  );
  const formRef = useRef(null);
  const { error, loading } = useSelector((state) => state?.patient);
  const {
    isAuthenticated,
    info,
    loading: infoLoading,
    error: meError,
  } = useSelector((state) => state?.auth);
  const authenticatedPatient = info?.patient;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dateFormat = "YYYY-MM-DD";
  const [imageUrl, setImageUrl] = useState(null);
  const [progress, setProgress] = useState(0);
  const [medImage, setMedImage] = useState(null);
  const [medProgress, setMedProgress] = useState(0);
  const [formData, setFormData] = useState([]);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);

  const is_patient_entered = useMemo(() => {
    const role = isAuthenticated ? RolesHelper?.getRole() : {};
    return !role?.is_cashier && !role?.is_security && isAuthenticated;
  }, [isAuthenticated]);

  const postImage = async (formData) => {
    const { onSuccess, onError, file, onProgress } = formData;
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }
    const newFormData = new FormData();
    newFormData.append("image", file);
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/images/`,
        newFormData,
        {
          config,
          onUploadProgress: (event) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setProgress(percent);
            if (percent === 100) {
              setTimeout(() => setProgress(0), 1000);
            }
            onProgress({ percent: (event.loaded / event.total) * 100 });
          },
        }
      );
      setImageUrl({
        status: "done",
        id: data?.id,
        url: data?.image,
        uid: file?.uid,
        name: file?.name,
      });
      setProgress(0);
      onSuccess("Ok");
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        const errorKeys = Object.keys(error.response.data);
        const firstErrorKey = errorKeys.length > 0 ? errorKeys[0] : null;
        const errorMessage = firstErrorKey
          ? error?.response?.data[firstErrorKey][0]
          : "An error occurred.";
        formRef?.current?.setFields([
          {
            name: "id_image_url",
            errors: [errorMessage],
          },
        ]);
        onError({ errorMessage });
      } else {
        onError({ message });
        formRef?.current?.setFields([
          {
            name: "id_image_url",
            errors: [message],
          },
        ]);
      }
    }
  };

  const handleIdImageChange = (info) => {
    if (info?.file?.status === "removed") {
      setImageUrl(null);
      setProgress(0);
      formRef?.current?.setFields([
        {
          name: "id_image_url",
          errors: [],
        },
      ]);
    } else if (
      info?.file?.status !== "removed" &&
      info?.file?.status !== "done"
    ) {
      setImageUrl({
        uid: info?.file?.originFileObj?.uid,
        name: info?.file?.originFileObj?.name,
        status: info?.file?.status,
      });
    }
  };

  const postMedImage = async (formData) => {
    const { onSuccess, onError, file, onProgress } = formData;
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }
    const newFormData = new FormData();
    newFormData.append("image", file);
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/images/`,
        newFormData,
        {
          config,
          onUploadProgress: (event) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setMedProgress(percent);
            if (percent === 100) {
              setTimeout(() => setMedProgress(0), 1000);
            }
            onProgress({ percent: (event.loaded / event.total) * 100 });
          },
        }
      );
      setMedImage({
        status: "done",
        id: data?.id,
        url: data?.image,
        uid: file?.uid,
        name: file?.name,
      });
      setMedProgress(0);
      onSuccess("Ok");
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        const errorKeys = Object.keys(error.response.data);
        const firstErrorKey = errorKeys.length > 0 ? errorKeys[0] : null;
        const errorMessage = firstErrorKey
          ? error?.response?.data[firstErrorKey][0]
          : "An error occurred.";
        formRef?.current?.setFields([
          {
            name: "med_rec_image_url",
            errors: [errorMessage],
          },
        ]);
        onError({ errorMessage });
      } else {
        onError({ message });
        formRef?.current?.setFields([
          {
            name: "med_rec_image_url",
            errors: [message],
          },
        ]);
      }
    }
  };

  const handleMedImageChange = (info) => {
    if (info?.file?.status === "removed") {
      setMedImage(null);
      setMedProgress(0);
      formRef?.current?.setFields([
        {
          name: "med_rec_image_url",
          errors: [],
        },
      ]);
    } else if (
      info?.file?.status !== "removed" &&
      info?.file?.status !== "done"
    ) {
      setMedImage({
        uid: info?.file?.originFileObj?.uid,
        name: info?.file?.originFileObj?.name,
        status: info?.file?.status,
      });
    }
  };

  const resetVariables = () => {
    setImageUrl(null);
    setProgress(0);
    setMedImage(null);
    setMedProgress(0);
    setFormData([]);
    formRef?.current?.resetFields();
    if (error) {
      dispatch(resetPatientError());
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getMe());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (is_patient_entered) {
      if (authenticatedPatient) {
        setFormData([
          {
            name: "first_name",
            value: authenticatedPatient?.first_name
              ? authenticatedPatient?.first_name
              : "",
          },
          {
            name: "last_name",
            value: authenticatedPatient?.last_name
              ? authenticatedPatient?.last_name
              : "",
          },
          {
            name: "email",
            value: authenticatedPatient?.email
              ? authenticatedPatient?.email
              : "",
          },
          {
            name: "phone_number",
            value: authenticatedPatient?.phone_number
              ? authenticatedPatient?.phone_number
              : "",
          },
          {
            name: "dob",
            value: authenticatedPatient?.dob
              ? dayjs(authenticatedPatient?.dob)
              : "",
          },
          {
            name: "state",
            value: authenticatedPatient?.state
              ? authenticatedPatient?.state
              : "",
          },
          {
            name: "address",
            value: authenticatedPatient?.address
              ? authenticatedPatient?.address
              : "",
          },
          {
            name: "city",
            value: authenticatedPatient?.city ? authenticatedPatient?.city : "",
          },
          {
            name: "zipcode",
            value: authenticatedPatient?.zipcode
              ? authenticatedPatient?.zipcode
              : "",
          },
          {
            name: "patient_id",
            value: authenticatedPatient?.patient_id
              ? authenticatedPatient?.patient_id
              : "",
          },
          {
            name: "patient_id_exp_date",
            value: authenticatedPatient?.patient_id_exp_date
              ? dayjs(authenticatedPatient?.patient_id_exp_date)
              : "",
          },
          {
            name: "medical_recommendation",
            value: authenticatedPatient?.medical_recommendation
              ? authenticatedPatient?.medical_recommendation?.text
              : "",
          },
          {
            name: "rec_exp_date",
            value: authenticatedPatient?.rec_exp_date
              ? dayjs(authenticatedPatient?.rec_exp_date)
              : "",
          },
        ]);
        setMedImage(
          authenticatedPatient?.med_rec_image_url
            ? {
                url: authenticatedPatient?.med_rec_image_url,
                name: "Med Image",
                status: "done",
              }
            : null
        );
        setImageUrl(
          authenticatedPatient?.id_image_url
            ? {
                url: authenticatedPatient?.id_image_url,
                name: "ID Image",
                status: "done",
              }
            : null
        );
      }
    } else {
      setFormData([
        {
          name: "first_name",
          value: info?.first_name ? info?.first_name : "",
        },
        {
          name: "last_name",
          value: info?.last_name ? info?.last_name : "",
        },
        {
          name: "email",
          value: info?.email ? info?.email : "",
        },
      ]);
      setImageUrl(null);
      setMedImage(null);
    }
  }, [authenticatedPatient, info, is_patient_entered]);

  useEffect(() => {
    if (error && !loading) {
      for (let key in error) {
        formRef?.current?.setFields([
          {
            name: key, // Field name you want to set the error for
            errors: [error[key][0]], // Assuming your error has a 'message' field
          },
        ]);
      }
      if (error?.Error) {
        openNotificationWithIcon("error", "Server Error", error?.Error);
        dispatch(resetPatientError("Error"));
      }
    }
  }, [dispatch, error, loading, openNotificationWithIcon]);

  const updateMe = (params) => {
    dispatch(patchMe(params))?.then((res) => {
      if (res?.type === "patch_me/fulfilled") {
        dispatch(getMe());
      } else if (res?.type === "patch_me/rejected") {
        if (res?.payload?.detail || res?.payload?.SERVER_ERROR) {
          if (res?.payload?.detail) {
            openNotificationWithIcon("error", "detail", res?.payload?.detail);
          }
          if (res?.payload?.SERVER_ERROR) {
            openNotificationWithIcon(
              "error",
              "Server Error",
              res?.payload?.SERVER_ERROR
            );
          }
          dispatch(resetAuthError());
        } else {
          const error = res?.payload;
          for (let key in error) {
            formRef?.current?.setFields([
              {
                name: key, // Field name you want to set the error for
                errors: [error[key][0]], // Assuming your error has a 'message' field
              },
            ]);
          }
        }
      }
    });
  };

  return (
    <>
      {contextHolder}
      <Content
        className="profile-cont"
        style={{
          backgroundColor: token?.Layout?.contentBg,
        }}>
        <ProfileHeader
          token={token}
          resetVariables={() => {
            setIsConfirmModalOpened(false);
            resetVariables();
          }}
        />
        <Form
          className={classNames("profile-cont__form", {
            "profile-cont__formBig": infoLoading || loading,
          })}
          ref={formRef}
          layout="vertical"
          onFinish={(values) => {
            if (is_patient_entered) {
              const modifiedValues = {
                ...values,
                dob: values?.dob ? Utils?.format_date(values?.dob) : undefined,
                patient_id_exp_date: values?.patient_id_exp_date
                  ? Utils?.format_date(values?.patient_id_exp_date)
                  : undefined,
                rec_exp_date: values?.rec_exp_date
                  ? Utils?.format_date(values?.rec_exp_date)
                  : undefined,
                medical_recommendation: values?.medical_recommendation
                  ? {
                      text: values?.medical_recommendation,
                    }
                  : undefined,
                id_image_url: imageUrl?.url ? imageUrl?.url : null,
                med_rec_image_url: medImage?.url ? medImage?.url : null,
              };
              dispatch(
                patchPatient({
                  id: authenticatedPatient?.id,
                  ...modifiedValues,
                })
              )?.then((res) => {
                if (res?.type === "patch_patient/fulfilled") {
                  dispatch(getMe());
                  dispatch(resetPatientError());
                }
              });
            } else {
              updateMe(values);
            }
          }}
          fields={formData}>
          {infoLoading || loading ? (
            <Spin size="large" />
          ) : (
            <>
              <div className="profile-cont__form__formItemWrapper">
                <Form.Item
                  className="profile-cont__form__formItemWrapper__formItem"
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter first name.",
                    },
                  ]}
                  label={<Typography.Text>First Name</Typography.Text>}
                  colon={false}>
                  <Input
                    type="text"
                    placeholder="First Name"
                    size="large"
                    onChange={() => {
                      if (is_patient_entered) {
                        if (error && error["first_name"]) {
                          dispatch(resetPatientError("first_name"));
                        }
                      } else {
                        if (meError && meError["first_name"]) {
                          dispatch(resetAuthError("first_name"));
                        }
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  className="profile-cont__form__formItemWrapper__formItem"
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter last name.",
                    },
                  ]}
                  label={<Typography.Text>Last Name</Typography.Text>}
                  colon={false}>
                  <Input
                    type="text"
                    placeholder="Last Name"
                    size="large"
                    onChange={() => {
                      if (is_patient_entered) {
                        if (error && error["last_name"]) {
                          dispatch(resetPatientError("last_name"));
                        }
                      } else {
                        if (meError && meError["last_name"]) {
                          dispatch(resetAuthError("last_name"));
                        }
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  className="profile-cont__form__formItemWrapper__formItem"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                    {
                      validator: Utils?.emailValidator,
                    },
                  ]}
                  label={<Typography.Text>Email</Typography.Text>}
                  colon={false}>
                  <Input
                    type="text"
                    placeholder="Email"
                    size="large"
                    onChange={() => {
                      if (is_patient_entered) {
                        if (error && error["email"]) {
                          dispatch(resetPatientError("email"));
                        }
                      } else {
                        if (meError && meError["email"]) {
                          dispatch(resetAuthError("email"));
                        }
                      }
                    }}
                  />
                </Form.Item>
              </div>
              {is_patient_entered ? (
                <>
                  <div className="profile-cont__form__formItemWrapper">
                    <Form.Item
                      className="profile-cont__form__formItemWrapper__formItem"
                      name="phone_number"
                      rules={[
                        {
                          required: true,
                          message: "Please enter phone number.",
                        },
                      ]}
                      label={<Typography.Text>Phone Number</Typography.Text>}
                      colon={false}>
                      <PhoneNumberInput
                        size="large"
                        onChange={() => {
                          if (error && error["phone_number"]) {
                            dispatch(resetPatientError("phone_number"));
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="profile-cont__form__formItemWrapper__formItem"
                      name="dob"
                      label={<Typography.Text>Date Of Birth</Typography.Text>}
                      colon={false}>
                      <DatePicker
                        style={{
                          width: "100%",
                        }}
                        size="large"
                        format={dateFormat}
                        allowClear={true}
                        onChange={() => {
                          if (error && error["dob"]) {
                            dispatch(resetPatientError("dob"));
                            formRef?.current?.setFields([
                              {
                                name: "dob",
                                errors: [], // Clear the error
                              },
                            ]);
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="profile-cont__form__formItemWrapper__formItem"
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: "Please enter State.",
                        },
                      ]}
                      label={<Typography.Text>State</Typography.Text>}
                      colon={false}>
                      <Input
                        type="text"
                        placeholder="State"
                        size="large"
                        onChange={() => {
                          if (error && error["state"]) {
                            dispatch(resetPatientError("state"));
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="profile-cont__form__formItemWrapper">
                    <Form.Item
                      className="profile-cont__form__formItemWrapper__formItem"
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Address.",
                        },
                      ]}
                      label={<Typography.Text>Address</Typography.Text>}
                      colon={false}>
                      <Input
                        size="large"
                        type="text"
                        placeholder="Address"
                        onChange={() => {
                          if (error && error["address"]) {
                            dispatch(resetPatientError("address"));
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="profile-cont__form__formItemWrapper__formItem"
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: "Please enter City.",
                        },
                      ]}
                      label={<Typography.Text>City</Typography.Text>}
                      colon={false}>
                      <Input
                        type="text"
                        placeholder="City"
                        size="large"
                        onChange={() => {
                          if (error && error["city"]) {
                            dispatch(resetPatientError("city"));
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="profile-cont__form__formItemWrapper__formItem"
                      name="zipcode"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Zip Code.",
                        },
                      ]}
                      label={<Typography.Text>Zip Code</Typography.Text>}
                      colon={false}>
                      <Input
                        size="large"
                        type="text"
                        placeholder="Zip Code"
                        onChange={() => {
                          if (error && error["zipcode"]) {
                            dispatch(resetPatientError("zipcode"));
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="profile-cont__form__formItemWrapper">
                    <Form.Item
                      className="profile-cont__form__formItemWrapper__formItem"
                      name="patient_id"
                      label={<Typography.Text>ID</Typography.Text>}
                      colon={false}>
                      <Input
                        size="large"
                        type="text"
                        placeholder="ID"
                        onChange={() => {
                          if (error && error["patient_id"]) {
                            dispatch(resetPatientError("patient_id"));
                            formRef?.current?.setFields([
                              {
                                name: "patient_id",
                                errors: [], // Clear the error
                              },
                            ]);
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="profile-cont__form__formItemWrapper__formItem"
                      name="patient_id_exp_date"
                      label={<Typography.Text>Expiration Date</Typography.Text>}
                      colon={false}>
                      <DatePicker
                        style={{
                          width: "100%",
                        }}
                        size="large"
                        format={dateFormat}
                        allowClear={true}
                        onChange={() => {
                          if (error && error["patient_id_exp_date"]) {
                            dispatch(resetPatientError("patient_id_exp_date"));
                            formRef?.current?.setFields([
                              {
                                name: "patient_id_exp_date",
                                errors: [], // Clear the error
                              },
                            ]);
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="profile-cont__form__formItemWrapperUpload">
                    <Form.Item
                      className="profile-cont__form__formItemWrapperUpload__formItem"
                      name="id_image_url">
                      <div
                        style={{
                          color: token?.colorPrimary,
                        }}>
                        <Upload
                          className="profile-cont__form__formItemWrapperUpload__formItem__upload"
                          block
                          customRequest={postImage}
                          maxCount={1}
                          showUploadList={imageUrl ? true : false}
                          accept="image/png, image/jpeg"
                          name="image"
                          fileList={imageUrl ? [imageUrl] : []}
                          onChange={handleIdImageChange}>
                          {imageUrl || progress > 0 ? null : (
                            <Button
                              className="profile-cont__form__formItemWrapperUpload__formItem__upload__btn"
                              type="default"
                              size="large"
                              block
                              icon={<UploadOutlined />}>
                              Upload ID Picture
                            </Button>
                          )}
                        </Upload>
                        {progress > 0 ? (
                          <Progress
                            percent={progress}
                            status={
                              imageUrl?.status === "error" ? "exception" : ""
                            }
                          />
                        ) : null}
                      </div>
                    </Form.Item>
                  </div>
                  <Divider
                    style={{
                      margin: "0px",
                    }}
                    type="horizontal"
                  />
                  <div className="profile-cont__form__formItemWrapper">
                    <Form.Item
                      className="profile-cont__form__formItemWrapper__formItem"
                      name="medical_recommendation"
                      label={<Typography.Text>Med Rec</Typography.Text>}
                      colon={false}>
                      <Input
                        size="large"
                        type="text"
                        placeholder="Med Rec"
                        onChange={() => {
                          if (error && error["medical_recommendation"]) {
                            dispatch(
                              resetPatientError("medical_recommendation")
                            );
                            formRef?.current?.setFields([
                              {
                                name: "medical_recommendation",
                                errors: [], // Clear the error
                              },
                            ]);
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="profile-cont__form__formItemWrapper__formItem"
                      name="rec_exp_date"
                      label={<Typography.Text>Expiration Date</Typography.Text>}
                      colon={false}>
                      <DatePicker
                        style={{
                          width: "100%",
                        }}
                        size="large"
                        format={dateFormat}
                        allowClear={true}
                        onChange={() => {
                          if (error && error["rec_exp_date"]) {
                            dispatch(resetPatientError("rec_exp_date"));
                            formRef?.current?.setFields([
                              {
                                name: "rec_exp_date",
                                errors: [], // Clear the error
                              },
                            ]);
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="profile-cont__form__formItemWrapperUpload">
                    <Form.Item
                      className="profile-cont__form__formItemWrapperUpload__formItem"
                      name="med_rec_image_url">
                      <div
                        style={{
                          color: token?.colorPrimary,
                        }}>
                        <Upload
                          className="profile-cont__form__formItemWrapperUpload__formItem__upload"
                          block
                          style={{
                            width: "100%",
                          }}
                          customRequest={postMedImage}
                          maxCount={1}
                          showUploadList={medImage ? true : false}
                          accept="image/png, image/jpeg"
                          name="image"
                          fileList={medImage ? [medImage] : []}
                          onChange={handleMedImageChange}>
                          {medImage || medProgress > 0 ? null : (
                            <Button
                              className="profile-cont__form__formItemWrapperUpload__formItem__upload__btn"
                              type="default"
                              size="large"
                              block
                              icon={<UploadOutlined />}>
                              Upload Med Rec Picture
                            </Button>
                          )}
                        </Upload>
                        {medProgress > 0 ? (
                          <Progress
                            percent={medProgress}
                            status={
                              medImage?.status === "error" ? "exception" : ""
                            }
                          />
                        ) : null}
                      </div>
                    </Form.Item>
                  </div>
                  <Divider
                    style={{
                      margin: "0px",
                    }}
                    type="horizontal"
                  />
                </>
              ) : null}
              <div className="profile-cont__form__btnWrapper">
                <Button
                  size="large"
                  block
                  onClick={() => {
                    setIsConfirmModalOpened(true);
                  }}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  size="large"
                  block
                  disabled={error}
                  onClick={() => {
                    formRef.current?.submit();
                  }}>
                  Save
                </Button>
              </div>
            </>
          )}
        </Form>
        <ConfirmModal
          isOpened={isConfirmModalOpened}
          title={"Are you sure ?"}
          subTitle={"You want to navigate ."}
          icon={
            <ExclamationCircleOutlined
              style={{ color: token?.colorWarning, fontSize: "24px" }}
            />
          }
          onCancel={() => {
            setIsConfirmModalOpened(false);
          }}
          onOk={() => {
            navigate(-1);
            setIsConfirmModalOpened(false);
            resetVariables();
          }}
          token={token}
        />
      </Content>
    </>
  );
}
