import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import axiosClient from "../../common/api/request";

export const getShopDetails = createAsyncThunk(
  "get_shop_details",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/shop_settings/`,
        { params },
        config
      );
      return data;
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue({
          Error: error?.message,
        });
      }
    }
  }
);