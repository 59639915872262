import React, { useRef, useState, useEffect, useCallback } from "react";
import "./CreatePatient.scss";
import {
  Modal,
  Form,
  Typography,
  Input,
  DatePicker,
  Upload,
  Button,
  Progress,
  Divider,
  message,
  Checkbox,
  notification,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useDispatch, useSelector } from "react-redux";
import { resetPatientError } from "../../features/patient/patientSLice";
import { PhoneNumberInput } from "../../components";
import axiosClient from "../../common/api/request";
import config from "../../config";
import Utils from "../../utils";

export default function CreatePatient({ title, isOpened, onOk, onCancel }) {
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = useCallback(
    (type, title, text) => {
      api[type]({
        message: title,
        description: text,
        placement: "top",
      });
    },
    [api]
  );
  const { width } = useWindowWidth();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const { error, loading } = useSelector((state) => state?.patient);
  const [imageUrl, setImageUrl] = useState(null);
  const [progress, setProgress] = useState(0);
  const [medImage, setMedImage] = useState(null);
  const [medProgress, setMedProgress] = useState(0);
  const dateFormat = "YYYY-MM-DD";
  const [isChecked, setIsChecked] = useState(true);

  const postImage = async (formData) => {
    const { onSuccess, onError, file, onProgress } = formData;
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }
    const newFormData = new FormData();
    newFormData.append("image", file);
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/images/`,
        newFormData,
        {
          config,
          onUploadProgress: (event) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setProgress(percent);
            if (percent === 100) {
              setTimeout(() => setProgress(0), 1000);
            }
            onProgress({ percent: (event.loaded / event.total) * 100 });
          },
        }
      );
      setImageUrl({
        status: "done",
        id: data?.id,
        url: data?.image,
        uid: file?.uid,
        name: file?.name,
      });
      setProgress(0);
      onSuccess("Ok");
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        const errorKeys = Object.keys(error.response.data);
        const firstErrorKey = errorKeys.length > 0 ? errorKeys[0] : null;
        const errorMessage = firstErrorKey
          ? error?.response?.data[firstErrorKey][0]
          : "An error occurred.";
        formRef?.current?.setFields([
          {
            name: "id_image_url",
            errors: [errorMessage],
          },
        ]);
        onError({ errorMessage });
      } else {
        onError({ message });
        formRef?.current?.setFields([
          {
            name: "id_image_url",
            errors: [message],
          },
        ]);
      }
    }
  };

  const handleIdImageChange = (info) => {
    if (info?.file?.status === "removed") {
      setImageUrl(null);
      setProgress(0);
      formRef?.current?.setFields([
        {
          name: "id_image_url",
          errors: [],
        },
      ]);
    } else if (
      info?.file?.status !== "removed" &&
      info?.file?.status !== "done"
    ) {
      setImageUrl({
        uid: info?.file?.originFileObj?.uid,
        name: info?.file?.originFileObj?.name,
        status: info?.file?.status,
      });
    }
  };

  const postMedImage = async (formData) => {
    const { onSuccess, onError, file, onProgress } = formData;
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }
    const newFormData = new FormData();
    newFormData.append("image", file);
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/images/`,
        newFormData,
        {
          config,
          onUploadProgress: (event) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setMedProgress(percent);
            if (percent === 100) {
              setTimeout(() => setMedProgress(0), 1000);
            }
            onProgress({ percent: (event.loaded / event.total) * 100 });
          },
        }
      );
      setMedImage({
        status: "done",
        id: data?.id,
        url: data?.image,
        uid: file?.uid,
        name: file?.name,
      });
      setMedProgress(0);
      onSuccess("Ok");
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        const errorKeys = Object.keys(error.response.data);
        const firstErrorKey = errorKeys.length > 0 ? errorKeys[0] : null;
        const errorMessage = firstErrorKey
          ? error?.response?.data[firstErrorKey][0]
          : "An error occurred.";
        formRef?.current?.setFields([
          {
            name: "med_rec_image_url",
            errors: [errorMessage],
          },
        ]);
        onError({ errorMessage });
      } else {
        onError({ message });
        formRef?.current?.setFields([
          {
            name: "med_rec_image_url",
            errors: [message],
          },
        ]);
      }
    }
  };

  const handleMedImageChange = (info) => {
    if (info?.file?.status === "removed") {
      setMedImage(null);
      setMedProgress(0);
      formRef?.current?.setFields([
        {
          name: "med_rec_image_url",
          errors: [],
        },
      ]);
    } else if (
      info?.file?.status !== "removed" &&
      info?.file?.status !== "done"
    ) {
      setMedImage({
        uid: info?.file?.originFileObj?.uid,
        name: info?.file?.originFileObj?.name,
        status: info?.file?.status,
      });
    }
  };

  useEffect(() => {
    if (isOpened) {
      if (error && !loading) {
        if (error?.detail || error?.Error) {
          if (error?.detail) {
            openNotificationWithIcon("error", "detail", error?.detail);
          }
          if (error?.Error) {
            openNotificationWithIcon("error", "Server Error", error?.Error);
          }
          dispatch(resetPatientError());
        } else {
          for (let key in error) {
            formRef?.current?.setFields([
              {
                name: key, // Field name you want to set the error for
                errors: [error[key][0]], // Assuming your error has a 'message' field
              },
            ]);
          }
        }
      }
    }
  }, [isOpened, error, loading, dispatch, openNotificationWithIcon]);

  return (
    <>
      {contextHolder}
      <Modal
        className="createPatient-cont"
        width={width <= 600 ? 300 : 520}
        title={title}
        open={isOpened}
        centered={true}
        destroyOnClose={true}
        okText="Save"
        okButtonProps={{
          htmlType: "submit",
          type: "primary",
          key: "submit",
          loading: loading && isOpened,
          disabled: error ? Object.keys(error)?.length !== 0 : false,
        }}
        onOk={() => {
          if (error) {
            dispatch(resetPatientError());
          }
          formRef?.current?.submit();
        }}
        onCancel={() => {
          onCancel();
          setImageUrl(null);
          setProgress(0);
          setMedImage(null);
          setMedProgress(0);
          setIsChecked(true);
          formRef?.current?.resetFields();
          dispatch(resetPatientError());
        }}>
        <Form
          className="createPatient-cont__form"
          ref={formRef}
          layout="vertical"
          onFinish={(values) => {
            const modifiedValues = {
              ...values,
              dob: values?.dob ? Utils?.format_date(values?.dob) : undefined,
              patient_id_exp_date: values?.patient_id_exp_date
                ? Utils?.format_date(values?.patient_id_exp_date)
                : undefined,
              rec_exp_date: values?.rec_exp_date
                ? Utils?.format_date(values?.rec_exp_date)
                : undefined,
              medical_recommendation: values?.medical_recommendation
                ? {
                    text: values?.medical_recommendation,
                  }
                : undefined,
              id_image_url: imageUrl?.url ? imageUrl?.url : undefined,
              med_rec_image_url: medImage?.url ? medImage?.url : undefined,
              is_in_queue: isChecked ? isChecked : undefined,
            };
            onOk(modifiedValues, () => {
              setImageUrl(null);
              setProgress(0);
              setMedImage(null);
              setMedProgress(0);
              setIsChecked(true);
              formRef?.current?.resetFields();
              dispatch(resetPatientError());
            });
          }}>
          <div className="createPatient-cont__form__formItemWrapper">
            <Form.Item
              className="createPatient-cont__form__formItemWrapper__formItem"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please enter first name.",
                },
              ]}
              label={<Typography.Text>First Name</Typography.Text>}
              colon={false}>
              <Input
                type="text"
                placeholder="First Name"
                size="large"
                onChange={() => {
                  if (error && error["first_name"]) {
                    dispatch(resetPatientError("first_name"));
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              className="createPatient-cont__form__formItemWrapper__formItem"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Please enter last name.",
                },
              ]}
              label={<Typography.Text>Last Name</Typography.Text>}
              colon={false}>
              <Input
                type="text"
                placeholder="Last Name"
                size="large"
                onChange={() => {
                  if (error && error["last_name"]) {
                    dispatch(resetPatientError("last_name"));
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="createPatient-cont__form__formItemWrapper">
            <Form.Item
              className="createPatient-cont__form__formItemWrapper__formItem"
              name="phone_number"
              rules={[
                {
                  required: true,
                  message: "Please enter phone number.",
                },
              ]}
              label={<Typography.Text>Phone Number</Typography.Text>}
              colon={false}>
              <PhoneNumberInput
                size="large"
                onChange={() => {
                  if (error && error["phone_number"]) {
                    dispatch(resetPatientError("phone_number"));
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              className="createPatient-cont__form__formItemWrapper__formItem"
              name="email"
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  validator: Utils?.emailValidator,
                },
              ]}
              label={<Typography.Text>Email</Typography.Text>}
              colon={false}>
              <Input
                type="text"
                placeholder="Email"
                size="large"
                onChange={() => {
                  if (error && error["email"]) {
                    dispatch(resetPatientError("email"));
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="createPatient-cont__form__formItemWrapper">
            <Form.Item
              className="createPatient-cont__form__formItemWrapper__formItem"
              name="patient_id"
              label={<Typography.Text>ID</Typography.Text>}
              colon={false}>
              <Input
                size="large"
                type="text"
                placeholder="ID"
                onChange={() => {
                  if (error && error["patient_id"]) {
                    dispatch(resetPatientError("patient_id"));
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              className="createPatient-cont__form__formItemWrapper__formItem"
              name="patient_id_exp_date"
              label={<Typography.Text>Expiration Date</Typography.Text>}
              colon={false}>
              <DatePicker
                style={{
                  width: "100%",
                }}
                size="large"
                format={dateFormat}
                allowClear={true}
                onChange={() => {
                  if (error && error["patient_id_exp_date"]) {
                    dispatch(resetPatientError("patient_id_exp_date"));
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="createPatient-cont__form__formItemWrapperUpload">
            <Form.Item
              className="createPatient-cont__form__formItemWrapperUpload__formItem"
              name="id_image_url">
              <div>
                <Upload
                  className="createPatient-cont__form__formItemWrapperUpload__formItem__upload"
                  block
                  style={{
                    width: "100%",
                  }}
                  customRequest={postImage}
                  maxCount={1}
                  showUploadList={imageUrl ? true : false}
                  accept="image/png, image/jpeg"
                  name="image"
                  fileList={imageUrl ? [imageUrl] : []}
                  onChange={handleIdImageChange}>
                  {imageUrl || progress > 0 ? null : (
                    <Button
                      className="createPatient-cont__form__formItemWrapperUpload__formItem__upload__btn"
                      type="default"
                      size="large"
                      block
                      icon={<UploadOutlined />}>
                      Upload ID Picture
                    </Button>
                  )}
                </Upload>
                {progress > 0 ? <Progress percent={progress} /> : null}
              </div>
            </Form.Item>
          </div>
          <Divider
            style={{
              margin: "0px",
            }}
            type="horizontal"
          />
          <div className="createPatient-cont__form__formItemWrapper">
            <Form.Item
              className="createPatient-cont__form__formItemWrapper__formItem"
              name="medical_recommendation"
              label={<Typography.Text>Med Rec</Typography.Text>}
              colon={false}>
              <Input
                size="large"
                type="text"
                placeholder="Med Rec"
                onChange={() => {
                  if (error && error["medical_recommendation"]) {
                    dispatch(resetPatientError("medical_recommendation"));
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              className="createPatient-cont__form__formItemWrapper__formItem"
              name="rec_exp_date"
              label={<Typography.Text>Expiration Date</Typography.Text>}
              colon={false}>
              <DatePicker
                style={{
                  width: "100%",
                }}
                size="large"
                format={dateFormat}
                allowClear={true}
                onChange={() => {
                  if (error && error["rec_exp_date"]) {
                    dispatch(resetPatientError("rec_exp_date"));
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="createPatient-cont__form__formItemWrapperUpload">
            <Form.Item
              className="createPatient-cont__form__formItemWrapperUpload__formItem"
              name="med_rec_image_url">
              <div>
                <Upload
                  className="createPatient-cont__form__formItemWrapperUpload__formItem__upload"
                  block
                  style={{
                    width: "100%",
                  }}
                  customRequest={postMedImage}
                  maxCount={1}
                  showUploadList={medImage ? true : false}
                  accept="image/png, image/jpeg"
                  name="image"
                  fileList={medImage ? [medImage] : []}
                  onChange={handleMedImageChange}>
                  {medImage || medProgress > 0 ? null : (
                    <Button
                      className="createPatient-cont__form__formItemWrapperUpload__formItem__upload__btn"
                      type="default"
                      size="large"
                      block
                      icon={<UploadOutlined />}>
                      Upload Med Rec Picture
                    </Button>
                  )}
                </Upload>
                {medProgress > 0 ? <Progress percent={medProgress} /> : null}
              </div>
            </Form.Item>
          </div>
          <Divider
            style={{
              margin: "0px",
            }}
            type="horizontal"
          />
          <div className="createPatient-cont__form__formItemWrapper">
            <Form.Item
              className="createPatient-cont__form__formItemWrapper__formItem"
              name="dob"
              label={<Typography.Text>Date Of Birth</Typography.Text>}
              colon={false}>
              <DatePicker
                style={{
                  width: "100%",
                }}
                size="large"
                format={dateFormat}
                allowClear={true}
                onChange={() => {
                  if (error && error["dob"]) {
                    dispatch(resetPatientError("dob"));
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              className="createPatient-cont__form__formItemWrapper__formItem"
              name="state"
              label={<Typography.Text>State</Typography.Text>}
              colon={false}>
              <Input
                type="text"
                placeholder="State"
                size="large"
                onChange={() => {
                  if (error && error["state"]) {
                    dispatch(resetPatientError("state"));
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="createPatient-cont__form__formItemWrapper">
            <Form.Item
              style={{
                width: "100%",
              }}
              name="address"
              label={<Typography.Text>Address</Typography.Text>}
              colon={false}>
              <Input
                size="large"
                type="text"
                placeholder="Address"
                onChange={() => {
                  if (error && error["address"]) {
                    dispatch(resetPatientError("address"));
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="createPatient-cont__form__formItemWrapper">
            <Form.Item
              className="createPatient-cont__form__formItemWrapper__formItem"
              name="city"
              label={<Typography.Text>City</Typography.Text>}
              colon={false}>
              <Input
                type="text"
                placeholder="City"
                size="large"
                onChange={() => {
                  if (error && error["city"]) {
                    dispatch(resetPatientError("city"));
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              className="createPatient-cont__form__formItemWrapper__formItem"
              name="zipcode"
              label={<Typography.Text>Zip Code</Typography.Text>}
              colon={false}>
              <Input
                size="large"
                type="text"
                placeholder="Zip Code"
                onChange={() => {
                  if (error && error["zipcode"]) {
                    dispatch(resetPatientError("zipcode"));
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="createPatient-cont__form__formItemWrapper">
            <Form.Item
              style={{
                width: "100%",
              }}
              name="is_in_queue"
              colon={false}>
              <Checkbox
                checked={isChecked}
                onChange={() => {
                  setIsChecked(!isChecked);
                  if (error && error["is_in_queue"]) {
                    dispatch(resetPatientError("is_in_queue"));
                  }
                }}>
                Add to queue at once.
              </Checkbox>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
}
