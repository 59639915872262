import React, { useRef, useEffect } from "react";
import "./PickUpPoint.scss";
import { Modal, Form, Typography, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { resetPatientError } from "../../features/patient/patientSLice";

export default function PickUpPoint({ title, isOpened, onOk, onCancel }) {
  const { loading, error, success } = useSelector((state) => state?.patient);
  const dispatch = useDispatch();
  const formRef = useRef(null);

  useEffect(() => {
    if (isOpened) {
      if (error && !loading) {
        for (let key in error) {
          formRef?.current?.setFields([
            {
              name: key, // Field name you want to set the error for
              errors: [error[key]], // Assuming your error has a 'message' field
            },
          ]);
        }
      }
    }
  }, [loading, error, isOpened]);

  useEffect(() => {
    if (isOpened) {
      if (success) {
        onCancel();
        formRef.current.resetFields();
        dispatch(resetPatientError());
      }
    }
  }, [success, dispatch, isOpened, onCancel]);

  return (
    <Modal
      className="pickUpPoint-cont"
      open={isOpened}
      title={title}
      centered={true}
      destroyOnClose={true}
      okText={"Save"}
      cancelButtonProps={{
        className: "pickUpPoint-cont__cancelBtn",
      }}
      okButtonProps={{
        size: "large",
        className: "pickUpPoint-cont__btn",
        htmlType: "submit",
        type: "primary",
        key: "submit",
        loading: loading,
        disabled: error ? Object.keys(error)?.length !== 0 : false,
      }}
      onCancel={() => {
        formRef.current.resetFields();
        onCancel();
        if (error) {
          dispatch(resetPatientError());
        }
      }}
      onOk={() => {
        formRef?.current?.submit();
      }}>
      <Form
        className="pickUpPoint-cont__form"
        ref={formRef}
        layout="vertical"
        onFinish={(values) => {
          onOk({
            ...values,
          });
        }}
        onFinishFailed={(error) => {
          console.log("error", error);
        }}>
        <div className="pickUpPoint-cont__form__formItemWrapper">
          <Form.Item
            className="pickUpPoint-cont__form__formItemWrapper__formItem"
            name="address"
            rules={[
              {
                required: true,
                message: "Please enter your Address1 Line 1!",
              },
            ]}
            label={
              <Typography.Text className="pickUpPoint-cont__form__formItemWrapper__formItem__text">
                Address Line 1
              </Typography.Text>
            }
            colon={false}>
            <Input
              placeholder="Address"
              size="large"
              onChange={() => {
                if (error && error["address"]) {
                  dispatch(resetPatientError("address"));
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="pickUpPoint-cont__form__formItemWrapper__formItem"
            name="city"
            rules={[
              {
                required: true,
                message: "Please enter your City!",
              },
            ]}
            label={
              <Typography.Text className="pickUpPoint-cont__form__formItemWrapper__formItem__text">
                City
              </Typography.Text>
            }
            colon={false}>
            <Input
              placeholder="City"
              size="large"
              onChange={() => {
                if (error && error["city"]) {
                  dispatch(resetPatientError("city"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="pickUpPoint-cont__form__formItemWrapper">
          <Form.Item
            className="pickUpPoint-cont__form__formItemWrapper__formItem"
            name="state"
            rules={[
              {
                required: true,
                message: "Please enter your State!",
              },
            ]}
            label={
              <Typography.Text className="pickUpPoint-cont__form__formItemWrapper__formItem__text">
                State
              </Typography.Text>
            }
            colon={false}>
            <Input
              placeholder="State"
              size="large"
              onChange={() => {
                if (error && error["state"]) {
                  dispatch(resetPatientError("state"));
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="pickUpPoint-cont__form__formItemWrapper__formItem"
            name="zipcode"
            rules={[
              {
                required: true,
                message: "Please enter your Zip Code!",
              },
            ]}
            label={
              <Typography.Text className="pickUpPoint-cont__form__formItemWrapper__formItem__text">
                Zip Code
              </Typography.Text>
            }
            colon={false}>
            <Input
              placeholder="Zip Code"
              size="large"
              onChange={() => {
                if (error && error["zipcode"]) {
                  dispatch(resetPatientError("zipcode"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="pickUpPoint-cont__form__formItemWrapper">
          <Form.Item
            className="pickUpPoint-cont__form__formItemWrapper__formItem"
            name="address2"
            label={
              <Typography.Text className="pickUpPoint-cont__form__formItemWrapper__formItem__text">
                Address Line 2
              </Typography.Text>
            }
            colon={false}>
            <Input
              placeholder="Address2"
              size="large"
              onChange={() => {
                if (error && error["address2"]) {
                  dispatch(resetPatientError("address2"));
                  formRef.current.setFields([
                    {
                      name: "address2",
                      errors: [],
                    },
                  ]);
                }
              }}
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}
