import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./SecurityGuy.scss";
import { Content } from "antd/es/layout/layout";
import { theme, notification, Pagination } from "antd";
import { UsergroupDeleteOutlined } from "@ant-design/icons";
import RolesHelper from "../../common/services/permissions/RolesHelper";
import { SecurityGuyHeader } from "./components";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useSearchParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  get_patients,
  postPatient,
} from "../../features/patient/patientActions";
import { resetPatientError } from "../../features/patient/patientSLice";
import { PatientList } from "../../components";
import { ConfirmModal, CreatePatient } from "../../modals";
import Utils from "../../utils";

export default function SecurityGuy() {
  const { useToken } = theme;
  const { token } = useToken();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = useCallback(
    (type, title, text) => {
      api[type]({
        message: title,
        description: text,
        placement: "top",
      });
    },
    [api]
  );
  const { width } = useWindowWidth();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state?.auth);
  const {
    error,
    loading,
    patientsData: { meta, results: patients },
  } = useSelector((state) => state?.patient);
  const role = useMemo(() => {
    return isAuthenticated ? RolesHelper?.getRole() : {};
  }, [isAuthenticated]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [is_patient_opened, set_is_patient_opened] = useState(false);

  const setUrlSearchParams = (newParams) => {
    const filteredSearchParams = Utils?.get_filtered_url_params(searchParams, {
      security_page: searchParams?.get("security_page")
        ? searchParams?.get("security_page")
        : undefined,
      security_perPage: searchParams?.get("security_perPage")
        ? searchParams?.get("security_perPage")
        : undefined,
      ...newParams,
    });
    setSearchParams(filteredSearchParams);
  };

  const handlePagination = (e, a) => {
    setUrlSearchParams({
      security_page: e,
      security_perPage: a,
    });
  };

  useEffect(() => {
    const params = Utils?.getUrlParamsAsObject(searchParams);
    const check_identity = (params) => {
      for (let key in params) {
        if (key !== "security_page" && key !== "security_perPage") {
          return true;
        }
      }
      return false;
    };
    let is_included = check_identity(params);
    if (!is_included) {
      dispatch(
        get_patients({
          page: searchParams?.get("security_page") || 1,
          per_page: searchParams?.get("security_perPage") || 10,
          is_in_queue: true,
        })
      );
    } else {
      setSearchParams({});
    }
  }, [dispatch, isAuthenticated, searchParams, setSearchParams]);

  useEffect(() => {
    if (location?.pathname === "/" && !is_patient_opened && role?.is_security) {
      if (error && !loading) {
        for (let key in error) {
          openNotificationWithIcon("error", key, error[key]);
        }
        dispatch(resetPatientError());
      }
    }
  }, [
    error,
    loading,
    location,
    role?.is_security,
    openNotificationWithIcon,
    dispatch,
    is_patient_opened,
  ]);

  const addPatient = (params, callBack) => {
    dispatch(postPatient(params))?.then((res) => {
      if (res?.type === "post_patient/fulfilled") {
        set_is_patient_opened(false);
        if (typeof callBack === "function") {
          callBack();
        }
        if (params?.is_in_queue) {
          dispatch(
            get_patients({
              page: searchParams?.get("page") || 1,
              per_page: searchParams?.get("per_page") || 10,
              is_in_queue: true,
            })
          );
        }
      }
    });
  };

  const removeFromQueue = (patient) => {
    setSelectedPatient(patient);
    setIsConfirmModalOpened(true);
  };

  return (
    <>
      {contextHolder}
      <Content
        className="securityGuy-cont"
        style={{
          backgroundColor: token?.Layout?.contentBg,
        }}>
        <SecurityGuyHeader
          token={token}
          setUrlSearchParams={setUrlSearchParams}
          set_is_patient_opened={set_is_patient_opened}
        />
        <PatientList
          loading={loading}
          patients={patients}
          removeFromQueue={removeFromQueue}
        />
        {meta?.count > 10 ? (
          <Pagination
            className="securityGuy-cont__pagination"
            align="end"
            disabled={loading}
            pageSize={searchParams?.get("security_perPage") || 10}
            current={searchParams?.get("security_page") || 1}
            defaultCurrent={1}
            total={meta?.count}
            responsive={true}
            onChange={handlePagination}
            showQuickJumper={width < 584 && width >= 577 ? false : true}
          />
        ) : null}
        <ConfirmModal
          token={token}
          isOpened={isConfirmModalOpened}
          title={"Are you sure"}
          subTitle={`You want to unqueue  ${
            selectedPatient?.first_name ? selectedPatient?.first_name : ""
          } ${selectedPatient?.last_name ? selectedPatient?.last_name : ""}
          ${
            selectedPatient?.phone_number
              ? `+1 ${Utils?.format_phone_number(
                  selectedPatient?.phone_number
                )}`
              : ""
          }
      ?`}
          icon={<UsergroupDeleteOutlined style={{ fontSize: "24px" }} />}
          onCancel={() => {
            setIsConfirmModalOpened(false);
            setSelectedPatient(null);
          }}
          onOk={() => {
            setIsConfirmModalOpened(false);
            setSelectedPatient(null);
            //TODO
            // Remove patient from queue and when request will be fulfilled call the function below
            dispatch(
              get_patients({
                page: searchParams?.get("page") || 1,
                per_page: searchParams?.get("per_page") || 10,
                is_in_queue: true,
              })
            );
          }}
        />
        <CreatePatient
          token={token}
          title={"Create New Patient"}
          isOpened={is_patient_opened}
          onCancel={() => {
            set_is_patient_opened(false);
          }}
          onOk={(params, callBack) => {
            addPatient(params, callBack);
          }}
        />
      </Content>
    </>
  );
}
