import React, { useEffect, useState } from "react";
import "./QueueDrawer.scss";
import { Drawer, Typography, Pagination, Input, Button } from "antd";
import {
  CloseOutlined,
  SearchOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { get_patients } from "../../../../features/patient/patientActions";
import { PatientList } from "../../../../components";
import { ConfirmModal } from "../../../../modals";
import { resetPatientError } from "../../../../features/patient/patientSLice";
import Utils from "../../../../utils";

export default function QueueDrawer({
  token,
  width,
  isOpened,
  close,
  handlePatient,
  openNotificationWithIcon,
}) {
  const dispatch = useDispatch();
  const {
    error,
    loading,
    patientsData: { meta, results: patients },
  } = useSelector((state) => state?.patient);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchVal, setSearchVal] = useState("");
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const getPatientsByFilter = (params) => {
    dispatch(
      get_patients({
        page: page ? page : undefined,
        per_page: perPage ? perPage : undefined,
        name: searchVal ? searchVal : undefined,
        is_in_queue: true,
        ...params,
      })
    );
  };

  useEffect(() => {
    if (isOpened) {
      dispatch(
        get_patients({
          page: 1,
          per_page: 10,
          is_in_queue: true,
        })
      );
    }
  }, [dispatch, isOpened]);

  const removeFromQueue = (patient) => {
    setSelectedPatient(patient);
    setIsConfirmModalOpened(true);
  };

  const handlePagination = (e, a) => {
    setPage(e);
    setPerPage(a);
    getPatientsByFilter({ page: e, per_page: a });
  };

  const onSearchInpChange = Utils?.debounce((e) => {
    setSearchVal(e.target.value?.toLowerCase());
    setPage(1);
    setPerPage(10);
    getPatientsByFilter({
      page: 1,
      per_page: 10,
      name: e.target.value?.toLowerCase()
        ? e.target.value?.toLowerCase()
        : undefined,
    });
  }, 500);

  useEffect(() => {
    if (isOpened) {
      if (error && !loading) {
        for (let key in error) {
          openNotificationWithIcon("error", key, error[key]);
        }
        dispatch(resetPatientError());
      }
    }
  }, [error, loading, openNotificationWithIcon, dispatch, isOpened]);

  const closeDrawer = () => {
    if (typeof close === "function") {
      close();
      setPage(1);
      setPerPage(10);
      setSearchVal("");
      setIsConfirmModalOpened(false);
      setSelectedPatient(null);
      if (error) {
        dispatch(resetPatientError());
      }
    }
  };

  return (
    <>
      <Drawer
        className="queDrawer-cont"
        style={{
          backgroundColor: token?.Layout?.contentBg,
        }}
        destroyOnClose={true}
        placement="left"
        width={900}
        title={
          <Typography.Text
            className="queDrawer-cont__title"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            Manage Queue
          </Typography.Text>
        }
        open={isOpened}
        closeIcon={false}
        extra={
          <div className="queDrawer-cont__extraWrapper">
            <Button
              size="small"
              type="text"
              onClick={closeDrawer}
              icon={
                <CloseOutlined
                  style={{
                    color: token?.icons?.whiteIcon,
                    fontSize: "24px",
                  }}
                />
              }
            />
          </div>
        }
        onClose={closeDrawer}>
        <div className="queDrawer-cont__contentWrapper">
          <Input
            style={{
              width: "100%",
            }}
            size="large"
            placeholder="Search Queued Patients"
            prefix={<SearchOutlined />}
            onChange={onSearchInpChange}
            allowClear
          />
          <PatientList
            isCashier={true}
            loading={loading}
            patients={patients}
            handlePatient={(patient) => {
              handlePatient(patient, () => {
                close();
                setPage(1);
                setPerPage(10);
                setSearchVal("");
                setIsConfirmModalOpened(false);
                setSelectedPatient(null);
                if (error) {
                  dispatch(resetPatientError());
                }
              });
            }}
            removeFromQueue={removeFromQueue}
          />
          {meta?.count > 10 ? (
            <Pagination
              className="queDrawer-cont__pagination"
              align="end"
              pageSize={perPage}
              current={page}
              defaultCurrent={1}
              total={meta?.count}
              responsive={true}
              onChange={handlePagination}
              showQuickJumper={width < 584 && width >= 577 ? false : true}
            />
          ) : null}
        </div>
      </Drawer>
      <ConfirmModal
        token={token}
        isOpened={isConfirmModalOpened}
        title={"Are you sure"}
        subTitle={`You want to unqueue  ${
          selectedPatient?.first_name ? selectedPatient?.first_name : ""
        } ${selectedPatient?.last_name ? selectedPatient?.last_name : ""}
        ${
          selectedPatient?.phone_number
            ? `+1 ${Utils?.format_phone_number(selectedPatient?.phone_number)}`
            : ""
        }
    ?`}
        icon={<UsergroupDeleteOutlined style={{ fontSize: "24px" }} />}
        onCancel={() => {
          setIsConfirmModalOpened(false);
          setSelectedPatient(null);
        }}
        onOk={() => {
          setIsConfirmModalOpened(false);
          setSelectedPatient(null);
          //TODO
          // Remove patient from queue and when request will be fulfilled call the function below
          getPatientsByFilter();
        }}
      />
    </>
  );
}
