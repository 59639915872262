import LocalStorageService from "../LocalStorageService";

const RolesHelper = {
  getRole: () => {
    const authData = LocalStorageService?.get("authorizationData");
    const groups = authData?.groups || [];

    if (Array?.isArray(groups) && groups?.length && groups) {
      const roleMapping = {
        is_cashier: "Cashier",
        is_security: "Security",
      };

      return Object.fromEntries(
        Object.entries(roleMapping)?.map(([key, value]) => [
          key,
          groups?.some(
            (role) => role?.label?.toLowerCase() === value?.toLowerCase()
          ),
        ])
      );
    }
    return {};
  },
  getPagesVisibility() {
    const isAuthenticated = LocalStorageService?.get("authorizationData")
      ?.access
      ? true
      : false;
    const role = this?.getRole();

    return {
      is_checkout_visible: !role?.is_cashier && !role?.is_security,
      is_favorites_visible: isAuthenticated && !role?.is_security,
      is_single_product_visible: !role?.is_security,
      is_profile_visible: isAuthenticated,
    };
  },
};

export default RolesHelper;
